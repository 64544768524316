<template lang="pug">
.brand-side-sub-nav
  om-heading.brand-side-sub-nav-title(h4) {{ pageName }}
  template(v-if="!useSettingSubmenus")
    ul.brand-side-sub-nav-list
      li.brand-side-sub-nav-list-item(v-for="item in realMenuItems")
        .brand-side-sub-nav-list-item-link.cursor-pointer(
          v-if="onlyEmit"
          @click="$emit('click', item.name)"
        ) {{ item.name }}
        router-link.brand-side-sub-nav-list-item-link(v-else :to="item.to" :class="item.classes") {{ item.name }}
  template(v-else v-for="groupItem in settingsGroupsWithSubMenus")
    ul.brand-side-sub-nav-list(v-if="groupItem.items.length")
      li.brand-side-sub-nav-group {{ groupItem.groupName }}
      ul.brand-side-sub-nav-list.brand-side-sub-nav-list-by-group(v-for="item in groupItem.items")
        li.brand-side-sub-nav-list-item
          .brand-side-sub-nav-list-item-link.cursor-pointer(
            v-if="onlyEmit"
            @click="$emit('click', item.name)"
          ) {{ item.name }}
          router-link.brand-side-sub-nav-list-item-link(v-else :to="item.to" :class="item.classes") {{ item.name }}
</template>
<script>
  export default {
    props: {
      menuItems: {
        type: Array,
      },
      onlyEmit: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        settingGroups: [
          {
            groupId: 'contact_information',
            groupName: this.$t('settingGroups.contactInformation'),
            subMenuNames: ['account_settings', 'change_email', 'change_password'],
            items: [],
          },
          {
            groupId: 'billing',
            groupName: this.$t('settingGroups.billing'),
            subMenuNames: ['billing_address', 'payment_method', 'invoice_history'],
            items: [],
          },
          {
            groupId: 'account',
            groupName: this.$t('settingGroups.account'),
            subMenuNames: [
              'conversion_goals',
              'user_experience_protector_settings',
              'domains',
              'account_sharing',
              'settings-code-insert',
              'apikey_settings',
              'experimental',
              'white_label_settings',
              'spam_protection',
              'features',
            ],
            items: [],
          },
        ],
      };
    },
    computed: {
      useSettingSubmenus() {
        return this.settingSubMenus.some((name) => this.$route.name.startsWith(name));
      },
      realMenuItems() {
        return this.menuItems.map(({ name, link, classes }) => {
          const to = link.includes('#') ? { hash: link } : { name: link };
          return {
            name,
            to,
            classes,
          };
        });
      },
      settingSubMenus() {
        return this.settingGroups.reduce((subMenus, settingGroup) => {
          subMenus.push(...settingGroup.subMenuNames);
          return subMenus;
        }, []);
      },

      settingsGroupsWithSubMenus() {
        return this.settingGroups.map((settingGroup) => {
          const subMenus = this.realMenuItems.filter(({ to }) =>
            settingGroup.subMenuNames.includes(to.name),
          );

          return {
            ...settingGroup,
            items: subMenus.length ? [...settingGroup.items, ...subMenus] : settingGroup.items,
          };
        });
      },

      pageName() {
        return this.$route.name === 'style_guide' ? this.$t('styleGuide') : this.$t('settings');
      },
    },
    mounted() {
      setTimeout(() => {
        this.$nextTick(() => {
          const id = this.$route.hash;
          if (id.length) {
            const offset = this.scrollTo(id);
            this.$scrollTo(id, offset, { easing: 'ease', cancelable: false });
          }
        });
      }, 250);
    },
    methods: {
      scrollTo(id) {
        const el = document.querySelector(id);

        if (el) {
          const rect = el.getBoundingClientRect();

          return rect ? rect.top : 0;
        }

        return 0;
      },
    },
  };
</script>

<style lang="sass">
  .brand-side-sub-nav
    position: sticky
    top: 0
    height: 100vh
    flex: 1
    background: #F6F7F9
    padding: 2.1875rem 1.875rem
    &-title
      margin-bottom: 1.375rem
    &-group
      font-size: 0.875rem
      font-weight: bold
    &-list
      list-style: none
      padding: 0
      margin: 0
      &-by-group
        padding-left: 0.75rem
      &-item
        padding: 2px 0
        margin: 8px 0
        line-height: 1.2
        &-link
          font-size: 14px
          line-height: 1.15
          color: #272727
          transition: .3s
          &.beta
            position: relative
            &:after
              content: "Beta"
              position: absolute
              top: 0
              right: -2.625rem
              color: white
              text-transform: uppercase
              font-size: 0.625rem
              line-height: 1
              background: #8445e2
              padding: 0.1875rem 0.375rem
              text-align: center
              z-index: -1
              pointer-events: none
          &:hover
            text-decoration: none
            color: var(--brand-primary-color)
          &.router-link-active
            color: var(--brand-primary-color)
</style>
